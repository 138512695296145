import React from 'react'
import LogoDark from './../assets/logo/Logo_Dark_512.png'

const Footer = () => {
  return (
    <div className='dark-bg'>
        <div className="container center-align padding-top">
            <img src={LogoDark} alt="" className='logo-nav padding-top'/>
            <p className='xs-margin-bottom white-text'>© Copyright 2022 - iD Developers</p>
            <p className="no-padding-or-margin white-text">contact@iddevelopers.net</p>
            <div className="padding-bottom"></div>
        </div>
    </div>
  )
}

export default Footer