import React from 'react'

export const calendly = () => {
  window.location.href="https://calendly.com/jacob-id-developers/30min";
}

export const schedule = "Schedule a call";

const Button = (props) => {

  return (
    <button className='btn btn-large waves-effect primary-btn rounded' onClick={props.fxn}>{props.txt}</button>
  )
}

export default Button