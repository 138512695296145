import React, { Component} from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar';
import Home from './components/HomeScreen/Home';
import ToYoutube from './components/ToYoutube';
import Footer from './components/Footer';
import './App.css'
import University from './components/University/University';
// import PrivateRoute from './components/Routes/PrivateRoute'

// const MainApp = lazy(() => import('./components/MainApp/MainApp'));


  class App extends Component{
  render(){
    return (
      <Router>
        <div className="app">
          <Navbar />
            <Routes>
                <Route exact path='/' element={<Home/>} />
                <Route exact path='/iDUniversity' element={<University />}/>
                {/* <Route path='/app' element={<MainApp />} /> */}
            </Routes>
            <Footer />
        </div>
      </Router>
    ) 
  }
}

export default App