import React from 'react'

const Card = ({icon, heading, paragraph}) => {
  return (
    <div className='container s-margin-bottom l-card-50-s-100'>
        <div className="card rounded padding-top small">
            <div className="card-content center-text center-align card-text container ">
                <i className={`${icon} header-icon light-text margin-top`}></i>
                <h2 className='light-text bold'>{heading}</h2>
                <p className='large-p'>{paragraph}</p>
            </div>
        </div>
    </div>
  )
}

export default Card