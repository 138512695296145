import React from 'react'
import Top from './Top'
import WhatWeDo from './WhatWeDo'
import OurWork from './OurWork'
import WorkedWith from './WorkedWith'

const Home = () => {
  return (
    <div className=''>
        <Top />
        <WhatWeDo />
        <OurWork />
        <WorkedWith />
    </div>
  )
}

export default Home