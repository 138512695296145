import { doc, getDoc, setDoc } from "firebase/firestore";
import { randomString } from "../components/Additional/Helper/Helper";
import { db } from "./init";

export async function SaveContact(contact) {
    try {
        const checkValid = [contact.firstName != '', contact.lastName != '', contact.email.includes('@') && contact.email.includes('.')]
        if(!checkValid[0] || !checkValid[1]){
            console.log("Input a first and last name");
            return false
        }
        if(!checkValid[2]){
            console.log("Input a valid email");
            return false
        }
        const exists = await AlreadyExists(contact.email.toLowerCase(), contact);
        if(exists) {
            console.log("Account already exists");
            return false;
        }
        await setDoc(doc(db, "emails", contact.email.toLowerCase()), contact);
        return true;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
}

async function AlreadyExists(id, contact){
    const docRef = doc(db, "emails", id);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()){
        if(docSnap.data().email.toLowerCase() === contact.email.toLowerCase()){
            return true;
        }
    }
    return false;
}