import React from 'react'
import Card from '../Additional/Card'
import Button, { calendly, schedule } from '../Additional/Button'

const WhatWeDo = () => {
    const simulations = {
        icon: "bi bi-headset-vr",
        heading: "Simulations",
        p: "Our team specializes in creating high-quality, realistic Virtual Reality, Augmented Reality, and Desktop simulations."
    }

    const games = {
        icon: "bi bi-controller",
        heading: "Games",
        p: "Have a game idea? Our experts will go from 0-100 in creating your mobile, desktop, VR, or game for any platform."
    }

    const saas = {
        icon: "bi bi-laptop",
        heading: "SaaS",
        p: "We know software-as-a-service is the future. Our team has run, developed and designed great SaaS models. Let us do it for you."
    }

  return (
    <div className='light-bg'>
        <div className='container center-align'>
            <h1 className='padding-top dark-text large-h1 margin-bottom'>What we do</h1>
            <Card icon={simulations.icon} heading={simulations.heading} paragraph={simulations.p}/>
            <Card icon={games.icon} heading={games.heading} paragraph={games.p}/>
            <Card icon={saas.icon} heading={saas.heading} paragraph={saas.p}/>
            <div className="padding-bottom"></div>
            <Button fxn={calendly} txt={schedule}/>
            <div className="padding-bottom"></div>
        </div>
    </div>
  )
}

export default WhatWeDo