import React from 'react'
import Button, { calendly, schedule } from '../Additional/Button'

const Top = () => {
  return (
    <div className='dark-bg'>
        <div className='container white-text l-left-m-center'>
            <h1 className='padding-top'>You have ideas.</h1>
            <h1>We have developers.</h1>
            <div className="padding-bottom"></div>
            <p className='hide-on-med-and-down'>Get your next project done quickly and correctly.</p>
            <Button fxn={calendly} txt={schedule}/>
            <p>Check out our <a href="#our-work" className='white-text nunito'>case studies</a></p>
            <div className="padding-bottom"  id="what-we-do"></div>
        </div>
    </div>
  )
}

export default Top