import React from 'react'
import Army from '../../assets/worked-with/army.png'
import Zel from '../../assets/worked-with/Zel.png'
import JF from '../../assets/worked-with/jflogo1.png'
import Dupont from '../../assets/worked-with/Dupont.png'
import DoD from '../../assets/worked-with/DoD.png'

const WorkedWith = () => {
  const calendly = () => {
    window.location.href="https://calendly.com/jacob-id-developers/30min";
  }
  return (
    <div className='purple-bg' id="our-company">
            <div className="container center-align white-text">
                <h2 className='padding-top'>Who we've worked with</h2>
                <p className='regular'>Our projects are always priority and are emphasized by those who have chosen to work with us.</p>
                <div className="row">
                  <div className="col s12 m2 offset-m1 s-margin-bottom"><img src={Army} alt="" className='fit-screen'/></div>
                  <div className="col s12 m2 s-margin-bottom"><img src={Zel} alt="" className='fit-screen'/></div>
                  <div className="col s12 m2 s-margin-bottom"><img src={JF} alt="" className='fit-screen'/></div>
                  <div className="col s12 m2 s-margin-bottom"><img src={Dupont} alt="" className='fit-screen'/></div>
                  <div className="col s12 m2"><img src={DoD} alt="" className='fit-screen'/></div>
                </div>
                <button className='btn btn-large waves-effect secondary-btn rounded' onClick={calendly}>Schedule a call</button>
                <div className="padding-bottom"></div>
            </div>
    </div>
  )
}

export default WorkedWith