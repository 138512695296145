import React, { useEffect, useState } from 'react'
import Links from './Links'
import M from 'materialize-css'
import LogoDark from './../../assets/logo/Logo_Dark_512.png'
import LogoLight from './../../assets/logo/Logo_Light_512.png'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    const [scroll, setScroll] = useState(false)
    const changeNav = () => {
        if(window.scrollY >= 66){
            setScroll(true);
        }
        else{
            setScroll(false);
        }
    }
    useEffect(() => {
            var sidenav = document.querySelectorAll('.sidenav');
            M.Sidenav.init(sidenav, { duration: 200, edge: 'right' });
            changeNav();
            window.addEventListener("scroll", changeNav)
        })
    const logo = scroll === true ? LogoLight : LogoDark;
    const navChange = scroll === true ? "nav-wrapper z-depth-3 nav-change white-bg" : "nav-wrapper z-depth-0"
    const bg = scroll === true ? "white-bg nav-height" : "dark-bg nav-height"
    const textColor = scroll === true ? "dark-text" : "white-text"
  return (
    <div className={bg}>
        <div className=''>
            <nav className={navChange}>
                <div className={bg}>
                    <div className="container ">
                        <NavLink to='/'><img src={logo} alt="" className='logo-nav '/></NavLink>
                        <a href="#" className={`sidenav-trigger ${textColor} right no-decoration `} data-target='mobile-nav'> <i className="material-icons">menu</i></a>
                        <Links sidenav={false} scroll={scroll}/>
                    </div>
                </div>
            </nav>
            <Links sidenav={true} scroll={scroll}/>
        </div>
    </div>
  )
}

export default Navbar