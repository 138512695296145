import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC1pbDLWokaAz3YN9dUUbrKSvFF97xZyr4",
  authDomain: "iddevelopers-4bed6.firebaseapp.com",
  projectId: "iddevelopers-4bed6",
  storageBucket: "iddevelopers-4bed6.appspot.com",
  messagingSenderId: "974189357111",
  appId: "1:974189357111:web:36bb18c14927b41592b7b2",
  measurementId: "G-35T713BZBT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);