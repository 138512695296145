import React, {useEffect} from 'react'
import M from 'materialize-css';
import CarouselCard from '../Additional/CarouselCard';
import TWIRP from '../../assets/project-logos/twirp.webp'
import Button, { calendly, schedule } from '../Additional/Button';
import ELITE from '../../assets/project-logos/elite.webp'
import VRB from '../../assets/project-logos/vrb.webp'

const OurWork = () => {
    useEffect(() => {
        var carousel = document.querySelectorAll('.carousel');
        M.Carousel.init(carousel, { duration: 200, fullWidth: true, shift: 10, padding: 10, noWrap: true, indicators: true, });
    })

    const elite = {
        img: ELITE,
        title: "E.L.I.T.E",
        desc: "One of our favorite projects to work on. E.L.I.T.E. is a training simulator designed to provide a realistic environment for U.A.S. sensor operators. Our crown acheivement in this was an automated and hyper-realistic A.I. system featuring family structures and daily routines."
    }

    const vrb = {
        img: VRB,
        title: "Virtual Round Ballers",
        desc: "VRB puts the player head-to-head with a real pro in a full scale basketball simulation. Our team had a hand in creating the current design which is slated to be deployed to public venues within the next few months."
    }

    const twirp = {
        img: TWIRP,
        title: 'twirp.ai',
        desc: "twirp.ai is a full-scale SaaS we developed to completely automate Twitter growth. It utilizes an algorithm that we developed that takes in a number of parameters and builds the user's top converting Twitter profile."
    }
  return (
    <div className='dark-bg'>
        <div className="container white-text"  id="our-work">
            <h1 className='padding-top'>Our Work</h1>
            <p className='nunito'>We have had the privilege to work on very interesting projects. From Air Force training simulators to full-scale NBA virtual reality basket ball experiences.</p>
            <div className="carousel carousel-slider l-card-50-s-100">
                    <CarouselCard img={elite.img} desc={elite.desc} title={elite.title}/>
                    <CarouselCard img={vrb.img} title={vrb.title} desc={vrb.desc}/>
                    <CarouselCard img={twirp.img} title={twirp.title} desc={twirp.desc}/>
            </div>
            <div className="center-align padding-top padding-bottom">
                <Button fxn={calendly} txt={schedule}/>

            </div>
        </div>
    </div>
  )
}

export default OurWork