import React from 'react'

const CarouselCard = ({img, title, desc}) => {
  return (
    <div className="row carousel-item">
        <div className="col s12 offset-m1 m10 margin-bottom">
            <div className={`card rounded large`}>
                <div className={`card-image `}>
                <img src={img}/>
                </div>
                <div className="card-content">
                <h2 className="dark-text bold center-align">{title}</h2>
                <p className='dark-text'>{desc}</p>
                </div>
            </div>
        </div>
  </div>
  )
}

export default CarouselCard