import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function Links({ scroll, sidenav }) {
    const calendly = () => {
        window.location.href="https://calendly.com/jacob-id-developers/30min";
      }
    const linkStyle = `light med-text noHover nav-link no-decoration montserrat ${scroll ? 'dark-text' : 'white-text'}`
    const mobileClass = sidenav === true ? 'sidenav' : 'hide-on-med-and-down';
    const mobileId = sidenav === true ? 'mobile-nav': '';
    const sidenavStyle = scroll === true ? 'white-bg': 'dark-bg';
    return (
        <ul className={`right ${mobileClass} ${sidenavStyle}`} id={mobileId}>
            <li><HashLink to='/#what-we-do' className={linkStyle}>Work with us</HashLink></li>
            <li><HashLink smooth to='/#our-work' className={linkStyle}>Case studies</HashLink></li>
            <li><HashLink smooth to='/#our-company' className={linkStyle}>Our company</HashLink></li>
            <li><NavLink smooth to='/iDUniversity' className={linkStyle}>iD University </NavLink></li>
            {
                sidenav === true && (
                    <li><button className={`btn waves-effect primary-btn rounded margin-left`} onClick={calendly}>Schedule a call</button></li>
                )
            }
        </ul>
      )
}
export default Links