import React,  { useState, useRef, useEffect } from 'react'
import Button from './Button'
import { floatIndent, floatRight } from './Helper/Helper';
import Bootcamp from '../../assets/bootcamp.png'
import { SaveContact } from '../../firebase/landingPage';
import { async } from '@firebase/util';

export default function LandingPage() {
    let infoDict = {
        firstName: '',
        lastName: '',
        email: ''
    };
    const [info, setInfo] = useState(infoDict);
    const [submitted, setSubmitted] = useState(false);
    const callbackCalled = useRef(false);
    function onChange(val){
        setInfo({...info, ...val});
        callbackCalled.current = false;
    }

    async function submitInfo(){
        let submit = await SaveContact(info);
        setSubmitted(submit);
        callbackCalled.current = false;
    }

    useEffect(() => {
        if(!callbackCalled.current){
            console.log(info);
            callbackCalled.current = true;
        }
    })
    return (
    <div>
        <div className='light-bg'>
            <div className='container center padding-bottom'>
                <div className="container dark-text padding-top">
                    {
                        submitted ? (
                            <div className="card rounded">
                            <img src={Bootcamp} alt="" className='fit-screen-main padding-top'/>
                            <div className="container row padding-bottom">
                                <h2>Thank you, we will keep in touch!</h2>
                            </div>
                        </div>
                        ) : (
                            <div className="card rounded">
                            <img src={Bootcamp} alt="" className='fit-screen-main padding-top'/>
                            <h1 className='large-h1 container'>Start Your Game Dev Journey Today</h1>
                            <p className='hide-on-med-and-down'>Learn the Skills Needed to Land Your Dream Job!</p>
                            <div className="container row">
                                <input type="text" className="input-field col l6 m12 s12" placeholder="First Name" id="first-name" onChange={(e) => {
                                    onChange({ firstName: e.target.value });
                                }}/>
                                <input type="text" className="input-field col l6 m12 s12" placeholder="Last Name" id="last-name" onChange={(e) => {
                                    onChange({ lastName: e.target.value });
                                }}/>
                                <input type="email" className="input-field" placeholder="Email" id="email" onChange={(e) => {
                                    onChange({ email: e.target.value });
                                }}/>
                                <div className="center padding-top">
                                    <Button fxn={submitInfo} txt="Join the waitlist"/>
                                    <p className='landing-p'>Sign up for updates and an exclusive discount</p>
                                </div>
                            </div>
                        </div>
                        )
                    }

                </div>
            </div>
        </div>
        <div className="dark-bg">
            <div className="container white-text padding-bottom">
                <h1 className="center large-h1 padding-top">Get better at Game Dev</h1>
                <div className="row valign-wrapper">
                    <div className="col l6 m12 s12">
                        <p>Anyone with the drive can get good at game dev, but it takes <b>learning from experience</b>.</p>
                        <p>Luckily, you don't have to work <b>alone</b>. It's always good to learn from <b>other people's</b> experience.</p>
                        <Button fxn={() => console.log('Submit')} txt="Join the waitlist"/> {/* Bring up modal to enter details */}
                    </div>
                    <div className="col l6 m12 s12 center">Img</div>
                </div>
            </div>
        </div>
        <div className='light-bg'>
            <div className="container padding-bottom">
                <h1 className="center large-h1 padding-top dark-text ">Make a living</h1>
                <div className="row valign-wrapper">
                    <div className="col l6 m6 s6 center">Img</div>
                    <div className="col l6 m6 s6">
                        <p className={`dark-text`}>It's no secret that entry-level software jobs <b>pay well</b>. Game developers make good money doing what they <b>love</b>.</p>
                        <p className={`dark-text`}>Learn the <b>skills</b> you need to get a job <b>fast</b>.</p>
                        <ul className={`padding-bottom dark-text`}>
                            <li>◉ Code architecure</li>
                            <li>◉ Programming</li>
                            <li>◉ Basic algorithms</li>
                            <li>◉ Game design</li>
                        </ul>
                        <Button fxn={() => console.log('Submit')} txt="Join the waitlist"/> {/* Bring up modal to enter details */}
                    </div>
                </div>
            </div>
        </div>
        <div className="dark-bg">
            <div className="container white-text padding-bottom">
                <h1 className="center large-h1 padding-top ">Enjoy your work</h1>
                <div className="row padding-top valign-wrapper">
                    <div className="col l6 m6 s6">
                        <p><b>Game development</b> is a budding field and an <b>excellent</b> intro into software development.</p>
                        <p>Not only do you get to go to work <b>excited</b> everyday, but you actually get to see <b>fulfillment</b> through the players' criticism.</p>
                        <Button fxn={() => console.log('Submit')} txt="Join the waitlist"/> {/* Bring up modal to enter details */}
                    </div>
                    <div className="col l6 m6 s6 center">Img</div>
                </div>
            </div>
        </div>
    </div>
    )
}
